import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Youtube from 'react-youtube'
import Content from '../content'
import './style.scss'

class Video extends Component {

  constructor(props) {
    super(props)
    this.state = { showTranscript: false }
    this.openTranscript = this.openTranscript.bind(this)
    this.closeTranscript = this.closeTranscript.bind(this)
  }

  openTranscript () {
    this.setState({ showTranscript: true})
  }

  closeTranscript () {
    this.setState({ showTranscript: false})
  }

render() {
  return (
    <div>
      <a rel="transcript" htmlFor="tubeplayer" title="Avaa tekstivastine" href="#transcript" onClick={this.openTranscript} aria-controls="transcript_content" tabIndex="0" aria-expanded="false">Näytä tekstivastine</a>
      <div className="iframeContainer" id="video">        
          <Youtube videoId={this.props.src} id='tubeplayer' />
      </div>
      { this.state.showTranscript ? 
      <div id="transcript" title="tekstivastine" aria-live="off" aria-atomic="true" aria-relevant="all" tabIndex="0" aria-expanded="false" role="article">
        <a href="#video" title="Sulje tekstivastine" onClick={this.closeTranscript}>Sulje tekstivastine</a>
        <Content content={this.props.transcript} />
      </div>: null }
          
      
    </div>
  )

}

}
Video.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string,
}

Video.defaultProps = {
  width: '420',
  height: '315',
  src: '',
}

export default Video
