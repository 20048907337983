import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/content'
import Heading from '../components/heading'
import Gif from '../components/gif'
import Video from '../components/video'

class Campaign extends Component {
  render() {
    const post = this.props.data.wordpressWpCampaings
    return (
      <div>
        <SEO title={post.title} description={post.excerpt} image={post.acf.video_still} />
        <Layout
          heroImg={post.acf.hero_kuva}
          current={post.acf.order}
          bgColor={post.acf.bgcolor}
          video={post.acf.hero_video}
          videoStill={post.acf.video_still}
        >
          <Heading heading={post.title} />
          <Content content={post.content} />
          {post.acf.tube_video ? (
            <Video
              src={post.acf.tube_video}
              title="Panananaama"
              transcript={post.acf.youtube_tekstivastine || ""}
            />
          ) : null}

          {post.acf.tube_video_2 ? (
            <Video
              src={post.acf.tube_video_2}
              title="Hurhur"
              transcript={post.acf.youtube_2_tekstivastine || ""}
            />
          ) : null}
          <div className="templateGifContainer">
            <Gif gif={post.acf.gif} videoStill={post.acf.gif_still} />
          </div>
        </Layout>
      </div>
    )
  }
}   


export default Campaign

export const pageQuery = graphql`
  query currentCampaingsQuery($id: String!) {
    wordpressWpCampaings(id: { eq: $id }) {
      title
      content
      excerpt
      acf {
        bgcolor
        order
        tube_video
        tube_video_2
        hero_video
        hero_kuva
        gif
        gif_still
        gif_alt_text
        video_still
        youtube_tekstivastine
        youtube_2_tekstivastine
      }
    }
  }
`
